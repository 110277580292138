import React from 'react';
import TurfCategory from '../TurfCategories/TurfCategory';

// css
import '../../Assets/css/Home.css';
import Navbar from './Navbar';
import Footer from './Footer';

const Home = () => {
    return (
        <div>
            <Navbar/>
            <TurfCategory />
            <Footer/>

        </div>
    )
}

export default Home