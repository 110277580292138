import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// turfs-logos images
import pestLogo from "../../Assets/images/Category-2.png";
import weedLogo from "../../Assets/images/Category-3.png";
import diseaseLogo from "../../Assets/images/Category-1-removebg-preview.png";
// weeds images
import axios from "axios";
import Loader from "../Loader";
import swal from "sweetalert";
import Cartempty from "../../Assets/images/no-product.png";
import NoImg from "../../Assets/images/No-Img.png"
import config from "../../Config";
// -------------------------------------------------

const TurfCategory = () => {
  // for popups
  const [locationModal, setLocationModal] = useState(false);
  const [largeModal, setLargeModal] = useState(false);

  const [errorMessage, setErrorMsg] = useState("");

  // for select fields
  const [location, setLocation] = useState([]);
  const [category, setCategory] = useState([]);
  const [turf_type, setTurfType] = useState([]);

  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [australian_states, setAustralian_states] = useState([])
  const [turfOption, setTurfOption] = useState([])

  // getting all categories
  useEffect(() => {
    axios.get(`${config.apiUrl}/category`)
      .then((response) => {
        const options = response.data.data.map((item) => {
          return ({ value: item.id, label: item.category_name })
        })
        setCategoriesOptions(options)
      })
      .catch((err) => console.log(err))
  }, [])

  // getting Turfs types
  useEffect(() => {
    axios.get(`${config.apiUrl}/locations`)
      .then((response) => {
        const options = response.data.data.map((item) => {
          return ({ value: item.id, label: item.state_name })
        })
        setAustralian_states(options)
      })
      .catch((err) => console.log(err))
  }, [])

  // getting Locations
  useEffect(() => {
    axios.get(`${config.apiUrl}/turf`)
      .then((response) => {
        const options = response.data.data.map((item) => {
          return ({ value: item.id, label: item.turf_type })
        })
        setTurfOption(options)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (!localStorage.getItem("lawn_id")) {
      localStorage.setItem(
        "lawn_id",
        window.location.href.split("?").slice(1).toString()
      );
    }

    if (!localStorage.getItem("popup")) {
      setLocationModal(true);
    }
  }, []);

  // getting user location
  useEffect(() => {
    async function getLocation() {
      let location = await fetch("https://ipapi.co/json");
      let data = await location.json();
      const currUserState = australian_states.filter(
        (state) => state.label === data.region
      );
      // setLocation(
      //   currUserState.length > 0
      //     ? currUserState
      //     : { value: "NSW", label: "New South Wales" }
      // );
    }
    getLocation();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [problems, setProblems] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    Problems();
  }, []);

  const Problems = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", localStorage.getItem("location"));
    formData.append("category", localStorage.getItem("category"));
    formData.append("turf_type", localStorage.getItem("turf_type"));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/category_location`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setProblems(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onGetProblems = async (e) => {
    e.preventDefault();
    setCategory(
      categoriesOptions.filter((option) => option.value == e.currentTarget.id)
    );
    localStorage.setItem("category", e.currentTarget.id);
    setLargeModal(false);
    setLoading(true);
    const formData = new FormData();
    formData.append("location", location.value);
    formData.append("turf_type", turf_type.value);
    formData.append("category", e.target.id);
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/category_location`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setProblems(response.data.data);
        localStorage.setItem("popup", true);
        setLoading(false);
      });
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (category.length > 0) {
    // setCategory(categoriesOptions.filter((option) => option.value == category));
    // } else {
    setCategory(
      categoriesOptions.filter(
        (option) => option.value == localStorage.getItem("category")
      )
    );
    // }

    // if (location?.length == 0) {
    setLocation(
      australian_states.filter(
        (option) => option.value == localStorage.getItem("location")
      )[0]
    );
    // }

    setTurfType(
      turfOption.filter(
        (option) => option.value == localStorage.getItem("turf_type")
      )
    );
  }, [categoriesOptions, australian_states, turfOption]);

  //   Displaying Data on Category Changed

  const onCategoryChange = async (e) => {
    setLoading(true);
    setCategory(e);
    localStorage.setItem("category", e.value);
    const formData = new FormData();
    formData.append("location", localStorage.getItem("location"));
    formData.append("turf_type", localStorage.getItem("turf_type"));
    formData.append("category", e.value);
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/category_location`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setProblems(response.data.data);
        localStorage.setItem("popup", true);
        setLoading(false);
      });
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      });
      setLoading(false);
    }
  };

  const onStateChange = async (e) => {
    setLoading(true);
    setLocation(e);
    localStorage.setItem("location", e.value);
    const formData = new FormData();
    formData.append("location", e.value);
    formData.append("category", localStorage.getItem("category"));
    formData.append("turf_type", localStorage.getItem("turf_type"));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/category_location`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setProblems(response.data.data);
        localStorage.setItem("popup", true);
        setLoading(false);
      });
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      });
      setLoading(false);
    }
  };

  const onTurfChange = async (e) => {
    setLoading(true);
    setTurfType(e);
    localStorage.setItem("turf_type", e.value);
    const formData = new FormData();
    formData.append("location", localStorage.getItem("location"));
    formData.append("turf_type", e.value);
    formData.append("category", localStorage.getItem("category"));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/category_location`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setProblems(response.data.data);
        localStorage.setItem("popup", true);
        setLoading(false);
      });
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      });
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="py-5 bg-light">
        <div className="container-box pb-4">
          <div className="top-main-heading">
            <div className="mb-md-0 mb-3 text-md-start text-center">
              {/* <h4 className="fw-bold mb-0 text-light-gray pe-sm-4">
              I HAVE A PROBLEM WITH
              </h4> */}
              <h4 className="fw-bold mb-0 text-light-gray pe-sm-4">
                I HAVE <span className="text-green">A PROBLEM WITH</span>
              </h4>
            </div>

            <div className="select-field1">
              <Select
                value={category}
                options={categoriesOptions}
                onChange={onCategoryChange}
                placeholder="Select Turf.."
              />
            </div>
            <div className="my-3 my-md-0 text-sm-center ">
              <h4 className="fw-bold mb-0 text-light-gray px-sm-4 px-2"> IN</h4>
            </div>
            <div className="select-field2">
              <Select
                options={turfOption}
                placeholder="Select Turf.."
                value={turf_type}
                onChange={onTurfChange}
              />
            </div>
            <div className="my-3 my-md-0 text-sm-center ">
              <h4 className="fw-bold mb-0 text-light-gray px-sm-4">
                {" "}
                AT <span className="text-green">LOCATION</span>
              </h4>
            </div>
            <div className="select-field">
              <Select
                options={australian_states}
                placeholder="Select State.."
                value={location}
                onChange={onStateChange}
              />
            </div>

            {/* paragraph */}
            <div className="mt-4 pt-2 w-100">
              <p className="paragraph main-top-heading mb-0 py-3 px-4" style={{ backgroundColor: "#0c4d291a", fontSize: 15 }}>
                The Problem Solver is for <a href="https://lawnpride.com.au/blogs/lawn-care-guide/what-is-an-established-lawn" className="text-green" style={{ fontWeight: "500" }}>Established Lawns</a> only. If you have a New Lawn, please review the <a href="https://lawnpride.com.au/pages/new-lawns" className="text-green" style={{ fontWeight: "500" }}>New Lawn blogs in our Learning Centre for methods of control.</a>
              </p>
            </div>
          </div>

          <div className="row">
            {problems.map((turf, i) => {
              const totalTreatProduct = turf?.products_data?.filter((obj) => obj.product_type == 1)
              return (
                <div
                  key={i}
                  className="col-xl-3 col-lg-3 col-md-4 col-sm-6 big-width"
                >
                  <Link
                    to={`${config.appUrl}turf-details/${turf.id}`}
                    className="text-decoration-none"
                  >
                    <div className="turf-card">
                      <img
                        src={
                          turf.thumbnail != null
                            ? turf.thumbnail
                            : NoImg
                        }
                        alt="turfImage"
                      />
                      <div className="turf-details">
                        <h5 className="fw-600 text-light-gray">{turf.title}</h5>
                        <p className="text-green">( {turf.name} )</p>
                        <h6 className="fw-600">
                          SOLUTIONS AVAILABLE:{" "}
                          {totalTreatProduct?.length}
                        </h6>

                        {/* <p className="text-light-gray">{turf.description}</p> */}
                        <div
                          className="bg-blog-ul"
                          style={{ height: "115px", color: "black" }}
                          dangerouslySetInnerHTML={{
                            __html: turf.description.slice(0, 120) + '...',
                          }}
                        />
                        <Link
                          to={`${config.appUrl}turf-details/${turf.id}`}
                          className="btn-green"
                        >
                          View Problem & Solution
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {problems.length == 0 ? (
        <div className="text-center mt-10" style={{ marginBottom: "30px" }}>
          <img src={Cartempty} />
          <h5 className="text-center "> No Problems Listed! </h5>
        </div>
      ) : null}

      {/* first modal */}
      <Modal show={locationModal} centered>
        <Modal.Header>
          <Modal.Title className="fw-bold text-green">
            Have a Problem With Your TURF?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="text-light-gray  mb-10">
              Select your Location to learn more and find a solution with our
              problem solver! <span className="text-danger">*</span>
            </p>
          </div>
          <div>
            <Select
              options={australian_states}
              placeholder="Select State.."
              onChange={(e) => {
                setLocation(e);
                setErrorMsg("");
              }}
            />
            <span className="text-danger">{errorMessage}</span>
          </div>
          <div className="mt-30">
            <p className="text-light-gray mb-10">
              What is your Turf type? <span className="text-danger">*</span>
            </p>
          </div>
          <div>
            <Select
              options={turfOption}
              placeholder="Select Turf type ..."
              isRequired
              onChange={(e) => setTurfType(e)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <Button
            onClick={() => {
              setLargeModal(true);
              setLocationModal(false);
              localStorage.setItem("location", location.value);
              localStorage.setItem("turf_type", turf_type.value);
            }}
            className="btn-success rounded-0"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* second modal */}
      <Modal
        show={largeModal}
        dialogClassName="modal-90w"
        centered
        size="xl"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="py-sm-5 py-4">
            <div className="banner-content">
              <h2 className="fw-bold text-green ">
                HAVE A PROBLEM WITH YOUR TURF?
              </h2>
              <p className="pb-4 pt-2 text-light-gray">
                Select your issue to learn more and find a solution with our
                problem solver!
              </p>

              <div className="row">
                <div className="col-md-4 px-3 mb-md-0 mb-4">
                  <div className="yurf-card" id="1" onClick={onGetProblems}>
                    <img src={pestLogo} id="1" alt="turf categories" height='107' />
                    <h4 className="mt-3 text-white" id="1">
                      PEST
                    </h4>
                  </div>
                </div>

                <div className="col-md-4 px-3 mb-md-0 mb-4">
                  <div className="yurf-card" id="2" onClick={onGetProblems}>
                    <img src={weedLogo} id="2" alt="turf categories" height='107' />
                    <h4 className="mt-3 text-white" id="2">
                      WEED
                    </h4>
                  </div>
                </div>

                <div className="col-md-4 px-3 mb-md-0 mb-4">
                  <div className="yurf-card" id="3" onClick={onGetProblems}>
                    <img src={diseaseLogo} id="3" alt="turf categories" height='107' />
                    <h4 className="mt-3 text-white" id="3">
                      DISEASE
                    </h4>
                  </div>
                </div>
              </div>
              <div className="mt-60">
                <div>
                  <a
                    className="font-18"
                    href="https://lawnpride.com.au/pages/learning-centre"
                    target="_blank"
                  >
                    <strong>Unsure of your issue? Learn more here</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TurfCategory;
