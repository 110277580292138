import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedin,
  faShopify,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Cartempty from "../../Assets/images/no-product.png";
import "../../Assets/css/TurfDetails.css";
import {
  faAdd,
  faArrowUp,
  faCancel,
  faTimes,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import NoImg from "../../Assets/images/No-Image-Placeholder.svg.png";
import NoImg1 from "../../Assets/images/No-Img.png";
import companyLogo from "../../Assets/images/logo.avif";
// view images
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader";
import Slider from "react-slick";
import { Modal } from "react-bootstrap";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import swal from "sweetalert";
import config from "../../Config";

const TurfDetail = () => {
  const [mainImg, setmainImg] = useState();
  const [imgViewer, setImgViewer] = useState(false);

  const [largeModal, setLargeModal] = useState(false);
  const targetDivRef = useRef(null);

  // dynamic options
  const [australian_states, setAustralian_states] = useState([])
  const [turfOption, setTurfOption] = useState([])
  // console.log(turfOption)



  // getting Turfs types
  useEffect(() => {
    axios.get(`${config.apiUrl}/locations`)
      .then((response) => {
        const options = response.data.data.map((item) => {
          return ({ value: item.id, label: item.state_name })
        })
        setAustralian_states(options)
        setState(
          options.find(
            (option) => option.value == localStorage.getItem("location")
          ).label
        );
      })
      .catch((err) => console.log(err))
  }, [])

  // getting Locations
  useEffect(() => {
    axios.get(`${config.apiUrl}/turf`)
      .then((response) => {
        const options = response.data.data.map((item) => {
          return ({ value: item.id, label: item.turf_type })
        })
        setTurfOption(options)
        setTurfType(
          options?.find(
            (option) => option.value == localStorage.getItem("turf_type")
          ).label
        );
      })
      .catch((err) => console.log(err))
  }, [])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    DisplayingData();
  }, []);

  const [turf_type, setTurfType] = useState([]);

  const params = useParams();

  const [isLoading, setLoading] = useState(false);
  const [problem_detail, setProblemDetail] = useState([]);
  const [images, setImages] = useState([]);
  const [problem_name, setProblemName] = useState("");
  const [state, setState] = useState("");

  const [products_list, setProdutsList] = useState("");
  const [all_product, setAllProduct] = useState("");
  const [output, setOutput] = useState([]);

  const [key_points, setKeyPoints] = useState([]);
  const [key_points2, setKeyPoints2] = useState([]);
  const [key_points3, setKeyPoints3] = useState([]);

  const [prevent_list, setPreventList] = useState([]);
  const [recover_list, setRecoverList] = useState([]);
  const [related_list, setRelatedList] = useState([]);

  const DisplayingData = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${config.apiUrl}/problems/${params.id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async (response) => {


        // console.log( "Products: ",  response.data.data.products_data)

        setProblemDetail(response.data.data.problems);
        setImages(response.data.data.problems[0]?.images?.split(","));
        setKeyPoints(
          response.data.data.problems[0]?.treat_key_points?.split("?,")
        );
        setKeyPoints2(
          response.data.data.problems[0]?.prevent_key_points?.split("?,")
        );
        setKeyPoints3(
          response.data.data.problems[0]?.recovery_key_points?.split("?,")
        );
        // const mainImgs = response.data.data.images.slice(0, 1).map((item) => {
        //   setmainImg(item.image_title);
        // });
        setmainImg(response.data.data.problems[0]?.images?.split(",")[0]);

        const datas = response.data.data.problems.map((item) => {
          setProblemName(item.title);
        });

        setProdutsList(
          response.data.data.products_data?.filter(
            (obj) => obj.product_type == 1
          )
        );

        setPreventList(
          response.data.data.products_data?.filter(
            (obj) => obj.product_type == 2
          )
        );
        setRecoverList(
          response.data.data.products_data?.filter(
            (obj) => obj.product_type == 3
          )
        );

        const response2 = await axios({
          method: "get",
          url: `${config.apiUrl}/allproducts`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          setAllProduct(response.data.data);
          setLoading(false);
        });

        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const carouselSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const carouselSettings2 = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    fade: false,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  const [cart_data, setCartData] = useState([]);
  const [all_meta_products, setAllMetaProducts] = useState([]);

  useEffect(() => {
    Product();
  }, []);

  const Product = async () => {
    try {
      const response2 = await axios({
        method: "get",
        url: `${config.apiUrl}/products`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        // console.log("p",response.data.data);
        setAllMetaProducts(response.data.data);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const [output2, setOutput2] = useState([]);
  const [output3, setOutput3] = useState([]);
  const [related_products, setRelatedProducts] = useState([]);

  useEffect(() => {
    if (products_list && all_product.length > 0) {
      const idsToKeep = products_list?.map((id) => parseInt(id.product_id));
      const idsToKeep2 = prevent_list?.map((id) => parseInt(id.product_id));
      const idsToKeep3 = recover_list?.map((id) => parseInt(id.product_id));
      // const relatedIds = related_list?.split(",").map((id) => parseInt(id));

      const filteredOutput = all_product.filter((item) =>
        idsToKeep.includes(item.id)
      );
      const ProductArray1 = filteredOutput.map((item2, index) => ({
        ...item2,
        Product_details: products_list?.filter(
          (obj) => obj?.product_id == item2?.id
        )[0],
      }));

      setOutput(ProductArray1);
      console.log("p1 : ",ProductArray1);

      // Output 2 -----------------------------

      const filteredOutput2 = all_product.filter((item) =>
        idsToKeep2.includes(item.id)
      );

      const ProductArray2 = filteredOutput2.map((item2, index) => ({
        ...item2,
        Product_details: prevent_list?.filter(
          (obj) => obj?.product_id == item2?.id
        )[0],
      }));
      // console.log(prevent_list)

      setOutput2(ProductArray2);

      // Output 3 -----------------------------

      const filteredOutput3 = all_product.filter((item) =>
        idsToKeep3.includes(item.id)
      );

      const ProductArray3 = filteredOutput3.map((item2, index) => ({
        ...item2,
        Product_details: recover_list?.filter(
          (obj) => obj?.product_id == item2?.id
        )[0],
      }));

      setOutput3(ProductArray3);
    }
  }, [products_list, all_product]);

  const [price, setPrice] = useState("");
  const [variant_id, setVariantId] = useState([]);
  const [itemId, setItemId] = useState("");

  const [itemsInStorage, setItemsInStorage] = useState(
    JSON.parse(localStorage.getItem("items")) || []
  );

  const [show, setShow] = useState(false);

  const [show_more, setShowMore] = useState(true);

  const [key_show, setKeyShow] = useState(Array(output.length).fill(false));
  const [key_show2, setKeyShow2] = useState(Array(output2.length).fill(false));
  const [key_show3, setKeyShow3] = useState(Array(output3.length).fill(false));

  const [show_more_key, setShowMoreKey] = useState([]);


  const [activeIngredients, setActiveIngredients] = useState([]);

  const getIngredients = async (product_id) => {
    try {
      const response = await axios({
        method: "get",
        url: `${config.apiUrl}/getActiveIngredient/${product_id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      console.log("getActiveIngredient", response.data.data);
      setActiveIngredients(response.data.data);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  return (
    <>
      <Navbar />
      {isLoading && <Loader />}
      {problem_detail.map((item, index) => {
        return (
          <div key={index}>
            <section className="py-5 bg-light">
              <div className="container-box blog-details mb-md-3">
                <div className="row align-items-end justify-content-between">
                  <h2 className="col-xxl-8 col-md-7 fw-bold text-green mb-4">
                    {item.title}
                  </h2>
                  <h6 className="col-xxl-4 col-md-5 text-light-gray d-md-block d-none">
                    Click on to view these images
                  </h6>
                </div>

                <div className="row align-items-center " key={"un" + index}>
                  <div className="col-xxl-8 col-md-7">
                    <div className="main-img">
                      <img
                        src={mainImg != null ? mainImg : NoImg1}
                        alt=""
                        onClick={() => setImgViewer(true)}
                      />
                      <br />
                      <span className="text-danger ">
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          className="me-2 pt-2"
                        ></FontAwesomeIcon>
                        Click on this image to zoom !
                      </span>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-md-5">
                    <h6 className="text-light-gray d-md-none mt-4 d-block">
                      Click on to view these images
                    </h6>

                    <div className=" ">
                      <div className="">
                        <Slider {...carouselSettings}>
                          <div>
                            <div className="row">
                              {images?.slice(0, 4)?.map((item, index) => {
                                return (
                                  <div className="col-md-6 mb-4">
                                    <img
                                      src={item}
                                      onClick={() => setmainImg(item)}
                                      alt=""
                                      className="w-100 h-190"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {images?.length > 4 ? (
                            <div>
                              <div className="row">
                                {images?.slice(4, 8)?.map((item, index) => {
                                  return (
                                    <div className="col-md-6 mb-4">
                                      <img
                                        src={`http://mysiscoict.com.au/yesha/admin/application/storage/app/public/${item.image_title}`}
                                        onClick={() =>
                                          setmainImg(item.image_title)
                                        }
                                        alt=""
                                        className="w-100 h-190"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-white">
              <div className="container-box">
                <div className="row py-5 border-bottom justify-content-center">
                  <div className="col-md-5 mb-sm-0 mb-4">
                    <div>
                      <div className="">
                        <h5 className="fw-bold h5">
                          SCIENTIFIC NAME :{" "}
                          <span className="fs-6 fw-600 d-md-inline d-block mt-md-0 mt-3">
                            {item.name}
                          </span>
                        </h5>
                      </div>

                      {/* <div className="mt-4">
                        <h5 className="fw-bold h5">
                          ORDER :{" "}
                          <span className="fs-6 fw-600 d-md-inline d-block mt-md-0 mt-3">
                            {item.orders}
                          </span>
                        </h5>
                      </div> */}
                    </div>
                  </div>

                  <div className="col-md-6 mt-md-0 mt-4">
                    <div className="">
                      {/* <div className="d-sm-flex justify-content-md-end align-items-center">
                        <h2 className="fw-bold text-green me-4 me-md-0">
                          {" "}
                          Share This Article on :{" "}
                        </h2>
                        <div className="d-flex mt-sm-0 mt-4">
                          <div className="share-media-btn">
                            <FontAwesomeIcon
                              icon={faFacebookF}
                            ></FontAwesomeIcon>
                          </div>
                          <div className="share-media-btn">
                            <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
                          </div>
                          <div className="share-media-btn">
                            <FontAwesomeIcon
                              icon={faLinkedin}
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="row pb-5 justify-content-center">
                  <div className="col-md-11 ">
                    <div
                      className="bg-blog-ul mt-5"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
      })}

      <section className="py-5 bg-light">
        <div className="container-box mb-90">
          <h3 className="fw-bold text-center text-light-gray font-26">
            Products to treat {problem_name} in {turf_type}, within {state}
          </h3>

          <div className="row mb-4">
            {output.map((product, i) => {
              const onKeyPoinst = () => {
                setLargeModal(false);
              };

              const onAddCart = (e) => {

                setLargeModal(true);
                getIngredients(product.id)

                const FilteredData = output.filter(
                  (item) => item.id == product.id
                );
                let all = FilteredData.map((item) => ({
                  ...item,
                }));
                setCartData(all);
                setPrice(FilteredData[0].variants[0].price);
                setVariantId(FilteredData[0].variants[0].id);
                // console.log("VariantId: ",FilteredData[0].variants[0].id);
                setItemId(FilteredData[0].id);

                const filteredOutput = all_product?.filter((item) =>
                  product?.Product_details?.complementry_products?.includes(
                    item.id
                  )
                );
                setRelatedProducts(filteredOutput);
              };

              let value;
              if (product?.metafields != undefined) {
                value = product?.metafields?.filter(
                  (obj) => obj.key == "active_ingredients"
                );
              } else {
                value = [];
              }

              const onKeyPoints = (i) => {
                const updatedShowMoreStates = [...key_show];
                updatedShowMoreStates[i] = !updatedShowMoreStates[i];
                setKeyShow(updatedShowMoreStates);
              };
              const renderDescription = () => {
                const keyPoints =
                  product.Product_details.key_points?.split("?")[0];

                if (key_show[i]) {
                  return (
                    <div
                      className="no-spacing"
                      // onClick={() => onKeyPoints(i)}
                      dangerouslySetInnerHTML={{
                        __html:
                          keyPoints
                          +
                          " <span> <a style='color:#197a45; font-size:14px; font-weight:500;padding: 10px' >Show Less</a></span>",
                      }}
                    />
                  );
                } else {
                  // Show a truncated version of the description, adjust the substring length as needed

                  if (keyPoints?.length > 100) {
                    const truncatedDescription =
                      keyPoints.substring(0, 100) + "...";
                    return (
                      <divss
                        className="no-spacing"
                        // onClick={() => onKeyPoints(i)}
                        dangerouslySetInnerHTML={{
                          __html: keyPoints
                          // truncatedDescription +
                          // " <span> <a style='color:#197a45; font-size:14px; font-weight:500;padding: 10px' >Show More</a></span>",
                        }}
                      />
                    );
                  } else {
                    const truncatedDescription = keyPoints.substring(0, 100);
                    return (
                      <div
                        className="no-spacing"
                        // onClick={() => onKeyPoints(i)}
                        dangerouslySetInnerHTML={{
                          __html: keyPoints
                          // truncatedDescription,
                        }}
                      />
                    );
                  }
                }
              };

              return (
                <div
                  key={i}
                  value="open"
                  className=" col-lg-4 col-md-4 col-sm-6 mt-5 cursor-pointer big-width"
                >
                  <div
                    className={
                      key_show[i] == 1
                        ? "product-card position-relative h-105"
                        : "product-card position-relative"
                    }
                  >
                    <img
                      src={
                        product.image?.src != null ? product.image?.src : NoImg
                      }
                      alt={product.title}
                      onClick={onAddCart}
                    />

                    <div className="product-details mt-4">
                      <h5
                        className="fw-600 text-light-gray"
                        onClick={onAddCart}
                      >
                        {product.title}
                      </h5>
                      <h6 className="text-green">
                        {value[0]?.value != undefined ? (
                          <div>
                            Active Ingredients :
                            <div
                              className="active-ingredient"
                              dangerouslySetInnerHTML={{
                                __html: value[0]?.value,
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </h6>
                      <div className="py-1 my-3 border-bottom"></div>

                      <p
                        className="text-light-gray "
                        style={{ textAlign: "left" }}
                      >
                        <p>{renderDescription()}</p>
                      </p>

                      <div className="bd-btn-cart-detail">
                        <button className="btn-green" onClick={onAddCart}>
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {output.length == 0 ? (
            <div className="text-center">
              <img src={Cartempty} alt="no img" />
              <h5> No Product is Available For this Problem</h5>
            </div>
          ) : null}
        </div>

        <div className="container-box mb-90">
          <h3 className="fw-bold text-center text-light-gray font-26">
            Products to prevent {problem_name} in {turf_type}, within {state}
          </h3>

          <div className="row mb-4">
            {output2.map((product, i) => {
              const onAddCart = () => {
                setLargeModal(true);
                const FilteredData = output2.filter(
                  (item) => item.id == product.id
                );
                let all = FilteredData.map((item) => ({
                  ...item,
                }));
                setCartData(all);
                setPrice(FilteredData[0].variants[0].price);
                setVariantId(FilteredData[0].variants[0].id);
                setItemId(FilteredData[0].id);

                const filteredOutput = all_product.filter((item) =>
                  product?.Product_details?.complementry_products?.includes(
                    item.id
                  )
                );
                setRelatedProducts(filteredOutput);
              };

              let value;
              if (product?.metafields != undefined) {
                value = product?.metafields?.filter(
                  (obj) => obj.key == "active_ingredients"
                );
              } else {
                value = [];
              }

              const onKeyPoints = (i) => {
                const updatedShowMoreStates = [...key_show2];
                updatedShowMoreStates[i] = !updatedShowMoreStates[i];
                setKeyShow2(updatedShowMoreStates);
              };
              const renderDescription = () => {
                const keyPoints =
                  product.Product_details.key_points?.split("?")[0];

                if (key_show2[i]) {
                  return (
                    <div
                      className="no-spacing"
                      // onClick={() => onKeyPoints(i)}
                      dangerouslySetInnerHTML={{
                        __html:
                          keyPoints
                        // +
                        // " <span> <a style='color:#197a45; font-size:14px; font-weight:500;padding: 10px' >Show Less</a></span>",
                      }}
                    />
                  );
                } else {

                  if (keyPoints?.length > 100) {
                    const truncatedDescription =
                      keyPoints.substring(0, 100) + "...";

                    return (
                      <div
                        className="no-spacing"
                        // onClick={() => onKeyPoints(i)}
                        dangerouslySetInnerHTML={{
                          __html: keyPoints
                          // truncatedDescription +
                          // " <span> <a style='color:#197a45; font-size:14px; font-weight:500;padding: 10px' >Show More</a></span>",
                        }}
                      />
                    );
                  } else {
                    const truncatedDescription = keyPoints.substring(0, 100);

                    return (
                      <div
                        className="no-spacing"
                        dangerouslySetInnerHTML={{
                          __html: keyPoints,
                        }}
                      />
                    );
                  }
                }
              };

              return (
                <div
                  key={i}
                  className=" col-lg-3 col-md-4 col-sm-6 mt-5 cursor-pointer big-width"
                >
                  <div
                    className={
                      key_show2[i] == 1
                        ? "product-card position-relative h-105"
                        : "product-card position-relative"
                    }
                  >
                    <img
                      src={
                        product.image?.src != null ? product.image?.src : NoImg
                      }
                      alt={product.title}
                      onClick={onAddCart}
                    />

                    <div className="product-details mt-4">
                      <h5
                        className="fw-600 text-light-gray"
                        onClick={onAddCart}
                      >
                        {product.title}
                      </h5>
                      <h6 className="text-green">
                        {value[0]?.value != undefined ? (
                          <div>
                            Active Ingredients :
                            <div
                              className="active-ingredient"
                              dangerouslySetInnerHTML={{
                                __html: value[0]?.value,
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </h6>
                      <div className="py-1 my-3 border-bottom"></div>

                      <p className=" "
                        style={{ textAlign: "left" }}>
                        {renderDescription()}
                      </p>

                      <div className="bd-btn-cart-detail">
                        <button className="btn-green" onClick={onAddCart}>
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {output2.length == 0 ? (
            <div className="text-center">
              <img src={Cartempty} alt="no img" />
              <h5> No Product is Available For this Problem</h5>
            </div>
          ) : null}
        </div>

        <div className="container-box">
          <h3 className="fw-bold text-center text-light-gray font-26">
            Products to recover from {problem_name} in {turf_type}, within{" "}
            {state}
          </h3>

          <div className="row mb-4">
            {output3.map((product, i) => {
              const onAddCart = () => {
                setLargeModal(true);
                const FilteredData = output3.filter(
                  (item) => item.id == product.id
                );

                let all = FilteredData.map((item) => ({
                  ...item,
                }));
                setCartData(all);
                setPrice(FilteredData[0].variants[0].price);
                setVariantId(FilteredData[0].variants[0].id);
                setItemId(FilteredData[0].id);

                const filteredOutput = all_product.filter((item) =>
                  product?.Product_details?.complementry_products?.includes(
                    item.id
                  )
                );
                setRelatedProducts(filteredOutput);
              };

              let value;
              if (product?.metafields != undefined) {
                value = product?.metafields?.filter(
                  (obj) => obj.key == "active_ingredients"
                );
              } else {
                value = [];
              }

              const onKeyPoints = (i) => {
                const updatedShowMoreStates = [...key_show3];
                updatedShowMoreStates[i] = !updatedShowMoreStates[i];
                setKeyShow3(updatedShowMoreStates);
              };
              const renderDescription = () => {
                const keyPoints =
                  product.Product_details.key_points?.split("?")[0];

                if (key_show3[i]) {
                  return (
                    <div
                      className="no-spacing"
                      // onClick={() => onKeyPoints(i)}
                      dangerouslySetInnerHTML={{
                        __html:
                          keyPoints
                        // +
                        // " <span> <a style='color:#197a45; font-size:14px; font-weight:500;padding: 10px' >Show Less</a></span>",
                      }}
                    />
                  );
                } else {
                  if (keyPoints?.length > 100) {
                    const truncatedDescription =
                      keyPoints.substring(0, 100) + "...";
                    return (
                      <div
                        className="no-spacing"
                        // onClick={() => onKeyPoints(i)}
                        dangerouslySetInnerHTML={{
                          __html: keyPoints,
                          // truncatedDescription +
                          // " <span> <a style='color:#197a45; font-size:14px; font-weight:500;padding: 10px' >Show More</a></span>",
                        }}
                      />
                    );
                  } else {
                    const truncatedDescription =
                      keyPoints.substring(0, 100);
                    return (
                      <div
                        className="no-spacing"
                        // onClick={() => onKeyPoints(i)}
                        dangerouslySetInnerHTML={{
                          __html: keyPoints,
                        }}
                      />
                    );
                  }
                }
              };

              return (
                <div
                  key={i}
                  className=" col-lg-3 col-md-4 col-sm-6 mt-5 cursor-pointer big-width"
                >
                  <div
                    className={
                      key_show3[i] == 1
                        ? "product-card position-relative h-105"
                        : "product-card position-relative"
                    }
                  >
                    <img
                      src={
                        product.image?.src != null ? product.image?.src : NoImg
                      }
                      alt={product.title}
                      onClick={onAddCart}
                    />

                    <div className="product-details mt-4">
                      <h5
                        className="fw-600 text-light-gray"
                        onClick={onAddCart}
                      >
                        {product.title}
                      </h5>
                      <h6 className="text-green">

                        {value[0]?.value != undefined ? (
                          <div>
                            Active Ingredients :
                            <div
                              className="active-ingredient"
                              dangerouslySetInnerHTML={{
                                __html: value[0]?.value,
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </h6>
                      <div className="py-1 my-3 border-bottom"></div>

                      <p
                        className=" "
                        style={{ textAlign: "left" }}
                      >
                        {renderDescription()}
                      </p>

                      <div className="bd-btn-cart-detail">
                        <button className="btn-green" onClick={onAddCart}>
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {output3.length == 0 ? (
            <div className="text-center">
              <img src={Cartempty} alt="no img" />
              <h5> No Product is Available For this Problem</h5>
            </div>
          ) : null}
        </div>
      </section>

      <section
        className={`image-viewer ${imgViewer ? "showViewer" : ""
          } d-flex align-items-center`}
      >
        <div className="container-box row justify-content-center py-5">
          <div className="col-md-10 px-0">
            <img src={mainImg != null ? mainImg : NoImg1} alt="" />
          </div>
        </div>
        <FontAwesomeIcon
          icon={faTimes}
          className="close-btn"
          onClick={() => setImgViewer(false)}
        ></FontAwesomeIcon>
      </section>
      <Footer />

      <Modal
        show={largeModal}
        dialogClassName="bd-set-width"
        centered
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        className="bd-set-width"
      >
        <div className="bd-header-modal" ref={targetDivRef}>
          {cart_data.map((item, index) => {
            return <h4>{item.title}</h4>;
          })}

          <button
            className="btn-closed-green"
            onClick={(e) => {
              setLargeModal(false);
              setPrice("");
            }}
          >
            <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
          </button>
        </div>

        <Modal.Body>
          <div className="">
            <div>
              {cart_data.map((item, index) => {
                const handleAddItem = () => {
                  if (!localStorage.getItem("lawn_id")) {
                    setShow(true);
                  } else {
                    const newArray = cart_data.map((item) => ({
                      ...item,
                      ...{ price: price },
                      ...{ variant_id: variant_id },
                      ...{ quantity: 1 },
                    }));
                    let updatedItems;
                    if (
                      itemsInStorage.some((item) => item.id == cart_data[0].id)
                    ) {
                      swal({
                        icon: "warning",
                        text: "This product is already added to Cart you can increase its quantity",
                      }).then((data) => {
                        setLargeModal(false);
                      });
                    } else {
                      updatedItems = [...itemsInStorage, newArray[0]];
                      localStorage.setItem(
                        "items",
                        JSON.stringify(updatedItems)
                      );
                      setItemsInStorage(updatedItems);
                      swal({
                        icon: "success",
                        text: "Product added to the Cart",
                      }).then((data) => {
                        setLargeModal(false);
                      });
                    }
                  }
                };

                let ShowMore;
                if (show_more == true) {
                  ShowMore = item.body_html.slice(0, 200) + "...";
                } else {
                  ShowMore = item.body_html;
                }
                const metaData = all_meta_products.filter(
                  (obj) => obj.id == item.id
                )[0];

                // activeIngredients
                let value = activeIngredients?.filter(
                  (obj) => obj.key == "active_ingredients"
                );


                return (
                  <div className="row" key={index}>
                    <div className="col-md-3 mb-md-0 mb-4">
                      <div className="related-modal">
                        <div>
                          <img
                            src={
                              item.image?.src != null ? item.image?.src : NoImg
                            }
                            height="320"
                            style={{objectFit: "contain"}}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div key={index}>
                        <div>
                          <div className="product-details ">
                            <div className="row align-items-center">
                              <div className="col-md-8">
                                <div>
                                  <h5 className="fw-600 text-light-gray">
                                    {item.title}
                                  </h5>
                                  <div className="d-flex align-items-center ">
                                    {/* <select
                                      className="from-control-select"
                                      onChange={(e) => {
                                        setPrice(
                                          e.target.options[
                                            e.target.selectedIndex
                                          ].getAttribute("data-label")
                                        );
                                        setVariantId(e.target.value);
                                      }}
                                    >
                                      {item.variants.map((items, i) => {
                                        return (
                                          <option
                                            data-label={items.price}
                                            value={items.id}
                                          >
                                            {item.title +
                                              " - " +
                                              items.weight +
                                              " " +
                                              items.weight_unit}
                                          </option>
                                        );
                                      })}
                                    </select> */}
                                  </div>
                                  <div className="variant-price">
                                    <div>
                                      {price != null && price.length != 0 ? (
                                        <h6 className="mb-0">
                                          Price : {"$ " + price}
                                        </h6>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <button
                                  className="btn-green"
                                  onClick={handleAddItem}
                                >
                                  <FontAwesomeIcon
                                    icon={faAdd}
                                    className="me-2"
                                  ></FontAwesomeIcon>
                                  Add to Cart
                                </button>
                                <a
                                  href="https://problemsolver.lawnpride.com.au"
                                  className="btn-green"
                                >
                                  Explore More Problems
                                </a>
                              </div>
                            </div>

                            <div className=" my-3 border-bottom"></div>
                            <h6
                              className="text-black"
                              style={{ marginBottom: "20px" }}
                            >
                              {value[0]?.value != undefined ? (
                                <div className="d-flexs ">
                                  <strong>Active Ingredients : </strong>
                                  <div
                                    className="active-ingredient text-green mt-2"
                                    dangerouslySetInnerHTML={{
                                      __html: value[0]?.value,
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </h6>
                            {item?.Product_details?.key_points != undefined ? (
                              <>
                                <h6 style={{ marginBottom: "5px" }}>
                                  <strong>Key Points</strong>
                                </h6>
                                <div
                                  className="bd-para"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.Product_details?.key_points?.split('?')[0],
                                  }}
                                ></div>
                              </>
                            ) : null}

                            {item?.Product_details?.key_points == undefined ||
                              item?.Product_details?.key_points?.length == 0 ? (
                              <>
                                <h6
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <strong>Description</strong>
                                </h6>
                                <p className="text-light-gray ">
                                  <div>
                                    <div
                                      className="bd-para"
                                      dangerouslySetInnerHTML={{
                                        __html: ShowMore,
                                      }}
                                    />
                                    <a
                                      className="show-more"
                                      onClick={() => setShowMore(!show_more)}
                                    >
                                      {show_more == true
                                        ? "Show More"
                                        : "Show Less"}
                                    </a>
                                  </div>
                                </p>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {item?.Product_details?.key_points != undefined ? (
                      <>
                        <h6 style={{ marginTop: "10px", marginBottom: "10px" }}>
                          <strong>Description</strong>
                        </h6>
                        <p className="text-light-gray ">
                          <div>
                            <div
                              className="bd-para"
                              dangerouslySetInnerHTML={{
                                __html: ShowMore,
                              }}
                            />
                            <a
                              className="show-more"
                              onClick={() => setShowMore(!show_more)}
                            >
                              {show_more == true ? "Show More" : "Show Less"}
                            </a>
                          </div>
                        </p>
                      </>
                    ) : null}
                  </div>
                );
              })}
              <div className="mt-40">
                <div>
                  {related_products?.length != 0 ? (
                    <h5 className="fw-bold  text-green">
                      Complementry Products
                    </h5>
                  ) : null}
                </div>
                <div
                  className="row mb-4 "
                  style={{ padding: "0px 28px  0px 23px" }}
                >
                  {related_products?.length >= 4 ? (
                    <Slider {...carouselSettings2}>
                      {related_products.map((product, i) => {
                        let value;
                        if (product?.metafields != undefined) {
                          value = product?.metafields?.filter(
                            (obj) => obj.key == "active_ingredients"
                          );
                        } else {
                          value = [];
                        }

                        return (
                          <div
                            key={i}
                            className="col-lg-3 col-md-3 col-sm-6 mt-3 mb-5"
                          >
                            <div className="product-card-related">
                              <div className="margin-auto">
                                <img
                                  src={
                                    product.image?.src != null
                                      ? product.image?.src
                                      : NoImg
                                  }
                                  alt="no img"
                                  height="130"
                                  className="w-100 "
                                />
                              </div>

                              <div className="product-details mt-4">
                                <h5 className="fw-600 text-light-gray">
                                  {product.title}
                                </h5>
                                <h6 className="text-green">
                                  {value[0]?.value != undefined ? (
                                    <div>
                                      Active Ingredients :
                                      <div
                                        className="active-ingredient"
                                        dangerouslySetInnerHTML={{
                                          __html: value[0]?.value,
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </h6>
                                <div className="py-1 my-3 border-bottom"></div>
                                <div>
                                  <button
                                    className="btn-green"
                                    onClick={(e) => {
                                      const targetDiv = targetDivRef.current;
                                      targetDiv.scrollIntoView({
                                        top: 0,
                                        left: 0,
                                        behavior: "instant",
                                      });

                                      setLargeModal(true);
                                      const FilteredData =
                                        related_products.filter(
                                          (item) => item.id == product.id
                                        );
                                      let all = FilteredData.map((item) => ({
                                        ...item,
                                      }));
                                      // console.log(all)
                                      setCartData(all);
                                      setPrice(
                                        FilteredData[0].variants[0].price
                                      );
                                      setVariantId(
                                        FilteredData[0].variants[0].id
                                      );
                                      setItemId(FilteredData[0].id);
                                    }}
                                  >
                                    View More
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <>
                      {related_products.map((product, i) => {
                        let value;
                        if (product?.metafields != undefined) {
                          value = product?.metafields?.filter(
                            (obj) => obj.key == "active_ingredients"
                          );
                        } else {
                          value = [];
                        }

                        return (
                          <div key={i} className="col-lg-4 mt-4 mb-5">
                            <div className="product-card-related">
                              <div className="margin-auto">
                                <img
                                  src={
                                    product.image?.src != null
                                      ? product.image?.src
                                      : NoImg
                                  }
                                  alt="no img"
                                  height="130"
                                  className="w-100 "
                                />
                              </div>

                              <div className="product-details mt-4">
                                <h5 className="fw-600 text-light-gray">
                                  {product.title}
                                </h5>
                                <h6 className="text-green">
                                  {value[0]?.value != undefined ? (
                                    <div>
                                      Active Ingredients :
                                      <div
                                        className="active-ingredient"
                                        dangerouslySetInnerHTML={{
                                          __html: value[0]?.value,
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </h6>
                                <div className="py-1 my-3 border-bottom"></div>
                                <div>
                                  <button
                                    className="btn-green"
                                    onClick={(e) => {
                                      setLargeModal(true);
                                      const FilteredData =
                                        related_products.filter(
                                          (item) => item.id == product.id
                                        );
                                      let all = FilteredData.map((item) => ({
                                        ...item,
                                      }));

                                      setCartData(all);
                                      setPrice(
                                        FilteredData[0].variants[0].price
                                      );
                                      setVariantId(
                                        FilteredData[0].variants[0].id
                                      );
                                      setItemId(FilteredData[0].id);
                                      document.body.scrollTop = 0;
                                      document.documentElement.scrollTop = 0;
                                    }}
                                  >
                                    View More
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal
          centered
          className="bd-back-login"
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Body>
            <div className="row">
              <div className="text-center bd-logos col-12">
                <img src={companyLogo}></img>
              </div>
            </div>

            <div className="mt-15">
              <div className="row">
                <div className="col-12 ">
                  <button
                    className="btn-green w-100"
                    onClick={() => {
                      localStorage.setItem("lawn_id", "0");
                      setShow(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} className="pr-10" /> Continue
                    as Guest user
                  </button>
                </div>
                <div className="col-12">
                  <a
                    href="https://lawnpride.com.au/account/login?return_url=%2Faccount"
                    className="btn-green w-100 text-center"
                  >
                    <FontAwesomeIcon
                      icon={faShopify}
                      className="pr-10 font-20"
                    />{" "}
                    Login with Shopify
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Modal>
    </>
  );
};

export default TurfDetail;
