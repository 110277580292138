import React, { useEffect, useState } from "react";
// products images
import Navbar from "./Navbar";
import Footer from "./Footer";
import swal from "sweetalert";
import axios from "axios";
import Loader from "../Loader";
import Cartempty from "../../Assets/images/no-product.png";
import { useNavigate } from "react-router-dom";
import NoImg from "../../Assets/images/No-Image-Placeholder.svg.png"
import config from "../../Config";

const Cart = () => {
  const [data, setData] = useState([]);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setData(JSON.parse(localStorage.getItem("items")));
    setQuantity(
      Array.from(
        { length: JSON.parse(localStorage.getItem("items"))?.length },
        () => 1
      )
    );
    setClicked(false);
  }, [clicked]);
  // const data =
  const [quantity, setQuantity] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let ColumnTotal = 0;
  data?.forEach((item, i) => {
    const itemTotal = parseFloat(item.price) * quantity[i];
    ColumnTotal += itemTotal;
  });
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onAddToCart = (e) => {
    const variant_id = [
      ...new Set(
        data.map((item) => "gid://shopify/ProductVariant/" + item.variant_id)
      ),
    ];
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("variant", variant_id);
    formData.append("qty", quantity);
    try {
      const response = axios({
        method: "Post",
        url: `${config.apiUrl}/checkout`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        window.location.href = response.data;
        localStorage.removeItem("items");
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      console.log(err);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <div className="py-5">
        <div className="container-box overflow-auto h-100">
          {data?.length >= 1 ? (
            <>
              <h1
                className="text-center mb-5"
                style={{ fontSize: "24px", color: "#434343" }}
              >
                Cart
              </h1>
              <form onSubmit={onAddToCart}>
                <div className="table-box">
                  <table
                    className="table cart-table text-center"
                    style={{ verticalAlign: "middle" }}
                  >
                    <thead>
                      <tr>
                        <th className="col-6 text-start">Product</th>
                        <th className="col-2">Quantity</th>
                        <th className="col-2 text-end">Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.map((items, i) => {
                        const Total =
                          parseFloat(items.price) * parseInt(quantity[i]);

                        const all_total = Total;
                        return (
                          <tr key={i}>
                            <td className="d-flex align-items-center text-start">
                              <div>
                                <img
                                  src={items.image?.src != null ? items.image?.src : NoImg}
                                  height={170}
                                  width='130'
                                  alt="product"
                                />
                              </div>
                              <div className="ms-4">
                                <h6>{items.title}</h6>
                                <p>$ {items.price}</p>
                              </div>
                            </td>
                            <td className="">
                              <div className="quan-counter ">
                                <div
                                  onClick={() => {
                                    if (quantity[i] > 1) {
                                      const newArray = [...quantity];
                                      newArray[i] = newArray[i] - 1;
                                      setQuantity(newArray);
                                    }
                                  }}
                                >
                                  –{" "}
                                </div>
                                <div>{quantity[i]}</div>
                                <div
                                  onClick={() => {
                                    const newArray = [...quantity];
                                    newArray[i] = newArray[i] + 1;
                                    setQuantity(newArray);
                                  }}
                                >
                                  +
                                </div>
                              </div>
                              <a
                                onClick={(e) => {
                                  swal({
                                    icon: "warning",
                                    title: "Are You sure ?",
                                    text: "You want to remove this item from Cart",
                                    buttons: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      setClicked(true);
                                      localStorage.setItem(
                                        "items",
                                        JSON.stringify(
                                          data.filter(
                                            (item) => item.id != items.id
                                          )
                                        )
                                      );
                                      // window.location.reload();
                                    }
                                  });
                                }}
                                className="text-light-gray d-block mt-1"
                                style={{ fontSize: "13px", cursor: "pointer" }}
                              >
                                Remove
                              </a>
                            </td>
                            <td className="text-end">
                              <p>${Total.toFixed(2)}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end flex-wrap mt-4 cart-bottom-details">

                  <div className="text-md-end mt-1 mt-md-0 mt-4">
                    <h6>Total: $ {parseFloat(ColumnTotal).toFixed(2)}</h6>
                    <p className="pt-2 text-light-gray">
                      Shipping & taxes calculated at checkout
                    </p>
                    <p className="mb-0">Continue shopping</p>
                    <p>
                      <input type="checkbox" name="" id="" required />
                      <span style={{ fontSize: "14px" }}>
                        {" "}
                        I have read and agreed with the
                        <a href="https://lawnpride.com.au/pages/terms-conditions">
                          {" "}
                          terms and conditions
                        </a>
                      </span>
                    </p>
                    <button className="btn-green">CHECKOUT</button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <div className="text-center">
              <img src={Cartempty} className=""></img>
              <h4>Your Cart is empty</h4>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
