import React from "react"
import "../Assets/css/Loader.css"

const Loader = () => {
  return (
    <div>
      <div className="spinner-loader">
        <div className="loader1">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  )
}

export default Loader
